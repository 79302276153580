import React from 'react';
import './Workgallery.css'; // Import CSS file for styling
import cam from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/Cam.jpeg.jpg';
import potrait from "C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/Potraits .jpg";
import wedding1 from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/Wedding pic.jpg';
import wedding2 from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/Weddingp.jpg';
import wedding3 from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/Weddingpict.jpg';
import vid1 from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/VID-20240407-WA0000.mp4';
import vid2 from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/VID-20240407-WA0001.mp4';
import vid3 from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/VID-20240407-WA0002.mp4';
const WorkGallery = () => {
    return (
        <div className="work-gallery">
            <div className="section">
                <h6>Photography</h6>
                <div className="card1">
                    <img src={cam} alt="Photo 1" />
                    <div className="description">
                        <h3>Camera </h3>
                    </div>
                </div>
                
                <div className="card1">
                    <img src={wedding2} alt="Photo 1" />
                    <div className="description">
                        <h3>Wedding image</h3>
                    </div>
                </div>
                <div className="card1">
                    <img src={wedding3} alt="Photo 1" />
                    <div className="description">
                        <h3>Wedding image</h3>
                    </div>
                </div>
                
                <div className="card1">
                    <img src={potrait} alt="Photo 1" />
                    <div className="description">
                        <h3>potraites</h3>
                    </div>
                </div>
                <div className="card1">
                    <img src={wedding1} alt="Photo 1" />
                    <div className="description">
                        <h3>Wedding image</h3>
                    </div>
                </div>
                {/* Add more card1s */}
            </div>

            <div className="section">
                <h6>Videography</h6>
                <div className="card1">
                    <video controls autoPlay muted>
                        <source src={vid1} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="description">
                        <h3>wedding vediography</h3>
                    </div>
                </div>
                <div className="card1">
                    <video controls muted autoPlay>
                        <source src={vid2} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="description">
                    <h3>wedding vediography</h3>
                    </div>
                </div>

                <div className="card1">
                    <video controls muted autoPlay>
                        <source src={vid3} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="description">
                        <h3>wedding vediography</h3>
                    </div>
                </div>
                {/* Add more card1s */}
            </div>
        </div>
    );
};

export default WorkGallery;

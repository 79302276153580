import React from 'react'
import servicesimage from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/servicesimage.png'
import './Services.css'
import{ Link } from 'react-router-dom';
const Services = () => {
  return (
    <>
      <div className='services'>
        <img src={servicesimage} className='s_image' />
        <h5 className='s_h5'>Our Services</h5>
        <p className='s_p'>
          "Discover seamless convenience and efficiency with our tailored services. Elevate your experience effortlessly. Join satisfied customers who have experienced the difference."
        </p>

        <div className='main_services'>

          <div className='f_row'>

            <div class="card_services">
              <div class="card-body">
                <h5 class="card-title">Photography</h5>
                <p class="card-text">Unlock the magic of moments frozen in time. Let our lenses be your storytellers, capturing memories that last a lifetime. Embrace the artistry of every frame, where emotions dance in harmony with light and shadow.</p>
              </div>
            </div>

            <div class="card_services">
              <div class="card-body">
                <h5 class="card-title">Vediography</h5>
                <p class="card-text">Embark on a journey through time with our videography expertise. We weave your moments into cinematic tales, preserving emotions and memories in motion.Trust us to create visual masterpieces.</p>
              </div>
            </div>

          </div>
          <div className='s_row'>

          <div class="card_services">
              <div class="card-body">
                <h5 class="card-title">Editing</h5>
                <p class="card-text">Elevate your moments to masterpiece status with our editing expertise. From subtle enhancements to striking transformations, we breathe life into your captures. Entrust us with your precious moments.</p>
              </div>
            </div>

            <div class="card_services">
              <div class="card-body">
                <h5 class="card-title">Freelancers</h5>
                <p class="card-text">Experience personalized moments captured through the lens of our freelance talents. Our diverse team of creatives brings unique perspectives to your story, ensuring every moment is captured with authenticity.</p>
              </div>
            </div>

          </div>
        </div>
         <div className='s_list'>
          <h5 className='s_h5'>Services we provide include:-</h5>
          <ul className='main-list'>

            <div >
              <h6 className='s-h6'>Photography:</h6>
              <div className='photography-list'>
              <div>
            <li> Potrait Photography</li>
            <li> Wedding Photography</li>
            <li>Birthday Photography</li>
            <li> New Born Baby Photography</li>
            <li>Product Photography</li>
            <li>Modeling Photography</li>
            <li>Fashion photography</li>
            </div>
            <div>
            <li>Landscape photography</li>
            <li>Macro photography</li>
            <li>Portrait photography</li>
            <li>Architecture photography</li>
            <li>Aerial photography</li>
            <li>Sports photography</li>
            <li>Documentary photography</li>
            <li>Poster photography</li>
            </div>
            </div>
            </div>

            <div>
            <h6 className='s-h6'>Vediography:</h6>
            <div className='vediography-list'>
              <div>
            <li>Wedding videography:
            <ol className='s-wv'>
              <li>Storytelling</li>
              <li>Teaser trailer</li>
              <li>Journalistic</li>
              <li>Traditional</li>
            </ol>
            </li>
            <li>Brand documentary</li>
            <li>Business explainer</li>
            <li>Drone videography</li>
            <li>Aerial videography</li>
            <li>Artistic</li>
            </div>

            <div>
            <li> product videography:
              <ol className='s-wv'>
                <li>Testimonial videos</li>
                <li>Social media videos</li>
                <li>Brand videos</li>
                <li>Product videos</li>
                <li>Educational videos</li>
                <li>Animation</li>
                <li>Interviews</li>
              </ol>
              <li>Short form</li>
              <li>Vintage</li>
              <li>Animation</li>
            </li>
            </div>
            </div>
            </div>
          </ul>
         </div>
         <div className="btn_links">
        <button className='btn_g btn'>
         <Link to='/workgallery' style={{textDecoration:'none',}}>Experience our portfolio</Link>
        </button>
        <button className='btn_rf btn'>
        <Link to='/appointus' style={{textDecoration:'none',}}>Here us now to avail these sevices</Link>
        </button>
      </div>
      <p className='list-warning'>&#9888; some services might be excluded from the list so for those services kindly add that while booking appointment</p>
      </div>
    </>
  )
}

export default Services

import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import './Slideshow.css'


const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  height: '500px'
  
}
const slideImages = [
  {
    url: 'https://cloud.appwrite.io/v1/storage/buckets/660bc09a632c78a37b51/files/6612c37266d30b8c1b03/view?project=66098019dd541f15e34c&mode=admin',
    caption: 'Slide 1'
  },
  {
    url: 'https://cloud.appwrite.io/v1/storage/buckets/660bc09a632c78a37b51/files/6612c7b47f8ed1cd43f4/view?project=66098019dd541f15e34c&mode=admin',
    caption: 'Slide 2'
  },
  {
    url: 'https://cloud.appwrite.io/v1/storage/buckets/660bc09a632c78a37b51/files/6612c83c9accbbae8c6f/view?project=66098019dd541f15e34c&mode=admin',
    caption: 'Slide 3'
  },
];

const Slideshow = () => {
    return (
      <div className="slide-container">
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
              <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
              </div>
            </div>
          ))} 
        </Slide>
      </div>
    )
}

export default Slideshow

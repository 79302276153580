import React,{useEffect,useState} from 'react'
import Slideshow from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/main/Slideshow.jsx';
import { TypeAnimation } from 'react-type-animation';
import './Home.css';
import Cards from './Cards';
import CouponSlideshow from './coupon.jsx'
import { Link } from 'react-router-dom';
const screenwidth = window.innerWidth;
const Body = () => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
    height: 'fit-content', // Set the height of the container to 100% of the viewport height
  };
  // 
 // Step 1: Fetch the JSX content
 
  return (
    <>
    <Slideshow className='Slideshow'/>
    <div style={containerStyle}>
    <TypeAnimation className='typeanimation'
      sequence={[
        // Same substring at the start will only be typed out once, initially
        'A proficient & skilled team of Photographers',
        1000, // wait 1s before replacing "Mice" with "Hamsters"
        'A proficient & skilled team of Vediographers',
        1000,
        'A proficient & skilled team of Editors',
        1000,
        'A proficient & skilled team of Freelancers',
        1000
      ]}
      wrapper="span"
      speed={50}
      // style={elementStyle}
      repeat={Infinity}
    />  
    </div>
<div className="content">
   <div className="first_content">
   MomentsCapture is more than a studio; it's a celebration of life's most beautiful moments. With a dedicated team, state-of-the-art equipment, and a commitment to storytelling, we turn ordinary photographs into extraordinary memories. Come, be a part of our photographic family, and let us capture your moments, so you can relive them again and again. Experience the magic of MomentsCapture today!
   </div>
</div>
  <Cards/>
  <div className="content second_content">
  Drawing from years of proven expertise, our journey is marked by successful projects, satisfied clients, and a commitment to excellence. Our extensive experience ensures that we not only meet but exceed expectations, delivering unparalleled results every time.
  </div>
<CouponSlideshow/>
      <div className="btn_links">
        <button className='btn_g btn'>
         <Link to='/workgallery' style={{textDecoration:'none',}}>Experience our portfolio</Link>
        </button>
        <button className='btn_rf btn'>
        <Link to='/reveiws_feedback' style={{textDecoration:'none',}}>Browse clients testimonials</Link>
        </button>
      </div>
    </>
    
  )
}

export default Body 

import React from 'react'
import { account } from '../appwriteConfig';
import * as FaIcons from "react-icons/fa6";
const Sociallogin = () => {
    const googleAuth = (e) =>{
            e.preventDefault();
            account.createOAuth2Session(
                'google',
                'http://localhost:3000',
                'http://localhost:3000/fail'
            )
    }

 const btnstyle ={
    border:'none',
    outline:'none',
    fontSize:'1em',
    background:'none',
 }
  return (
    <div>
      <b style={{textAlign:"center"}}>OR</b><br/>
      <button className='button-socials' onClick={(e)=>googleAuth(e)} style={btnstyle}><FaIcons.FaGoogle></FaIcons.FaGoogle>oogle</button>
    </div>
  )
}

export default Sociallogin

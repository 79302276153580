import React from 'react'
import './ConnectUs.css'
import * as FaIcons from "react-icons/fa6";
import { Link } from 'react-router-dom'
import map from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/a.png'
import { useNavigate } from 'react-router-dom';
const ConnectUs = () => {
  const navigate = useNavigate();
 const mapclick =() => {
  navigate('https://maps.app.goo.gl/7W22dNUX8zf7aiTCA')
}

  return (
    <>
      <div className='connectus'>
        <h5 className='contact-h5'>Join Our Community: Where Connections Flourish!</h5>
        <p className='contact-subhead'>"Join our inclusive community where connections spark growth, collaboration, and endless possibilities. Together, we're not just building networks, but fostering meaningful relationships that propel us forward. Embrace the power of connection – join us today and be part of something extraordinary!"</p>
        <div className='links-container'>
          <div>
            <h6 className='contact-h6'>Follow us now!</h6>
            <ul className='contact-socials'>
              <li><Link style={{ color: 'black' }}  target='_blank' to='https://www.facebook.com/momentscaptureindia?mibextid=ZbWKwL'><FaIcons.FaFacebook /></Link></li>
              <li><Link style={{ color: 'black' }} target='_blank' to='https://www.instagram.com/momentscapture.in/'><FaIcons.FaInstagram /></Link></li>
              <li><Link style={{ color: 'black' }} target='_blank' to='https://www.linkedin.com/in/moments-capture-57b188303'><FaIcons.FaLinkedinIn /></Link></li>
              <li><Link style={{ color: 'black' }} target='_blank'to='https://pin.it/5tC3fvP'><FaIcons.FaPinterest /></Link></li>
              <li><Link style={{ color: 'black' }} target='_blank' to='https://x.com/M0mentsCapture?t=_II4evElwuqD2xhcw3RDDQ&s=09'><FaIcons.FaXTwitter /></Link></li>
            </ul>
            <h6 className='contact-h6'>Reach us through:</h6>
            <ul className='contact-reach-us'>
              <li><Link style={{ color: 'black' }} to='https://wa.me/7291894384' target='_blank'><FaIcons.FaWhatsapp /></Link></li>
              <li><Link style={{ color: 'black' }} to='tel:+91-7291-894-384' target='_blank'><FaIcons.FaPhone /></Link></li>
              <li><Link style={{ color: 'black' }} to='mailto:in.momentscapture@gmail.com' target='_blank'><FaIcons.FaEnvelope /></Link></li>
              <li><Link style={{ color: 'black' }} to='https://t.me/momentscapture.in' target='_blank'><FaIcons.FaTelegram /></Link></li>
            </ul>
          </div>
          <div>
            <h6 className='contact-h6'>Find us on map:</h6>
            <Link to='https://maps.app.goo.gl/7W22dNUX8zf7aiTCA' target='_blank'><img src={map} className='map-image' /></Link>
            <p className='address'>Jaina Market, A Block, Chakkarpur, Sector 28, Gurugram, Haryana 122002</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConnectUs

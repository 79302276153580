import React, { useState, useEffect } from 'react';
import { useAuth } from '../utils/AuthContext.js';
import './Profile.css'; // Import CSS file
const UserProfile = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    if (user) {
      fetchUserData(user);
    }
  }, [user]);

  const fetchUserData = async (user) => {
    try {
      // Fetch user data
      // Here you might fetch additional user data including the user's role
      // For demonstration purposes, let's assume you have a function to fetch the user's role
      const role = await fetchUserRole(user);
      setUserRole(role);

      // Set user data
      setUserData(user);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setError('Error fetching user data. Please try again.');
      setLoading(false);
    }
  };

  // Example function to fetch user role
  const fetchUserRole = async (user) => {
    // Here you would fetch the user's role from your backend or any other source
    // For demonstration purposes, let's assume the role is stored in user metadata
    return user.metadata?.role || 'Normal User'; // If role is not found, default to 'Normal User'
  };

  return (
    <div className="user-profile-container">
      <h2>User Profile</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : user ? (
        <div className="user-profile-card">
          <p className="user-profile-item"><strong>Name:</strong> {userData.displayName || userData.name}</p>
          <p className="user-profile-item"><strong>Email:</strong> {userData.email}</p>
          <p className="user-profile-item"><strong>Role:</strong> {userRole}</p>
          <p className="user-profile-item"><strong>Verified:</strong> {userData.emailverified ? 'Yes' : 'No'}</p>
        </div>
      ) : (
        <p>Please log in to view your profile.</p>
      )}
    </div>
  );
};

export default UserProfile;

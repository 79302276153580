import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as FaIcons from "react-icons/fa6";
import './Sidebar.css'
import Home from '../main/Home.jsx';
import ConnectUs from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Pages/ConnectUs.js';
import { Link ,useNavigate} from 'react-router-dom';
import { useAuth } from '../../utils/AuthContext.js';

function Example() {
  const navigate = useNavigate()

  const {user,logoutUser} = useAuth()

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}  id='b' className='me-2'>
      <FaIcons.FaBars className='three-menu'  />
      </Button>

      <Offcanvas show={show} onHide={handleClose} >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Moments Capture</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='offbody'>
        <ul>
        <li>
        <Link to='/'>Home</Link>
        </li>
        <li>
        <Link to='/about'>About</Link>
        </li>
        <li>
        <Link to='/services'>Services</Link>
        </li>
        <li>
        <Link to='/contactus'>Connect with us</Link>
        </li>
        <li>
        <Link to='/workgallery'>See Our Portfolio</Link>
        </li>
        <li>
        <Link to='/appointus'>Hire us now</Link>
        </li>
        <li>
        <Link to='/reveiws_feedback'>Reveiws & Feedbacks</Link>
        </li>
       </ul>
       {user ?(
        <>
        <Link to='/profile'>User Profile</Link><br/>
        <button onClick={logoutUser} className="btn">Logout</button></>
       ):(
        <>
        <Link className="btn" to="/login">Login</Link>
        <Link className="btn" to="/register">SignUp</Link></>
       )}
      
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Example;
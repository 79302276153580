import React, { useState,useEffect } from 'react';
import emailjs from 'emailjs-com';
import './BookAppointment.css';

import { useAuth } from '../utils/AuthContext.js';
import { Client } from 'appwrite'; 

const BookAppointment = () => {
  const [couponCode, setCouponCode] = useState('');

  //
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    // Function to parse URL parameters
    const getUrlParameter = (name) => {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(window.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));


      //
      if (user && user.email) {
        // Fetch user data or profile image from Appwrite
        fetchUserData(user);
      }
    };

    // Get the coupon code from URL parameter
    const couponCodeFromUrl = getUrlParameter('coupon');

    // Set the coupon code in state
    setCouponCode(couponCodeFromUrl);
////user id code
  }, [user]);

  //
  const fetchUserData = async (user) => {
    try {
      const client = new Client(); // Create a new instance of the Appwrite client
      // Fetch user data including profile image from Appwrite
      const response = await client.users.get(user.$id);
      // Assuming the profile image is stored in the user's metadata
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const [formData, setFormData] = useState({
    userid:'',
    fullName: '',
    email: '',
    phone: '',
    contactMethod: '',
    couponcode:'',
    serviceType: '',
    preferredDate: '',
    preferredTime: '',
    additionalComments: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const { userid, fullName, email, phone, contactMethod, serviceType, preferredDate, preferredTime, additionalComments } = formData;
  
    emailjs.sendForm('service_wrxe8js', 'template_1npfuzh', e.target, 'mX_NdFATp_MosUAk3')
      .then((result) => {
        console.log(result.text);
        alert('Your appointment request has been sent successfully!');
        // Optionally, you can reset the form after successful submission
        // setFormData({ fullName: '', email: '', phone: '', ... });
      }, (error) => {
        console.log(error.text);
        alert('Sorry, there was an error sending your appointment request. Please try again later.');
      });
  
    const mailOptions = {
      from:{email}, // Replace with your email address
      to: 'in.momentscapture@gmail.com', // Replace with recipient's email address
      subject: 'New Appointment Booking',
      html: `
      <p>User id: ${userid}</p>
        <p>Name: ${fullName}</p>
        <p>Email: ${email}</p>
        <p>Phone: ${phone}</p>
        <p>Preferred Contact Method: ${contactMethod}</p>
        <p>Service Type: ${serviceType}</p>
        <p>Preferred Date: ${preferredDate}</p>
        <p>Preferred Time: ${preferredTime}</p>
        <p>Additional Comments: ${additionalComments}</p>
      `
    };
  
    // You can also handle sending the email directly from here, 
    // but using EmailJS library is recommended for client-side email sending.
  };


  return (
    <div className="form-container">
      <h2>Book Appointment</h2>
      <form onSubmit={handleSubmit}>
      <label>
          userId:
          <input type="text" name="userid" value={formData.userid || (user && user.$id)} onChange={handleChange} readOnly />

        </label>
        <label>
          Full Name:
          <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
        </label>
        <label>
          Email Address:
          <input type="email" name="email" value={formData.email} onChange={handleChange}required />
        </label>
        <label>
          Phone Number:
          <input type="tel" name="phone" value={formData.phone} onChange={handleChange}required />
        </label>
        <label>
          Preferred Contact Method:
          <select name="contactMethod" value={formData.contactMethod} onChange={handleChange}required>
            <option value="">Select...</option>
            <option value="email">Email</option>
            <option value="phone">Phone</option>
          </select>
        </label>
        <label>
          Type of Service:
          <select name="serviceType" value={formData.serviceType} onChange={handleChange}required>
            <option value="">Select...</option>
            <option value="photography">Photography </option>
            <option value="videography">Videography </option>
            <option value="editor">Editor </option>
            <option value="freelancer">Freelancer</option>
            <option value="rent-equitnemt">Rent Equipment </option>
            <option value="mixed services">Combination of services</option>
          </select>
        </label>
        <aside>mention the services in additionalComments elaborately.</aside>
        <label>
          coupon code:
          <input type="text" id="coupon" name="coupon" onChange={handleChange} value={couponCode} readOnly />
          
        </label>
        <label>
          Preferred Date:
          <input type="date" name="preferredDate" value={formData.preferredDate} onChange={handleChange} required/>
        </label>
        <label>
          Preferred Time:
          <input type="time" name="preferredTime" value={formData.preferredTime} onChange={handleChange}required />
        </label>
        <label>
          Additional Comments or Specific Requests:
          <textarea name="additionalComments" value={formData.additionalComments} onChange={handleChange}required />
        </label>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default BookAppointment;

import React from 'react'
import * as FaIcons from "react-icons/fa6";
import './Footer.css';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className='f'>
      <div className='footer'>
        <div className='footer_h'>
          <aside style={{width:'fit-content',textAlign:'center'}}>copyright-2023 ©momentscapture-All rights reserved</aside>
          </div>
          <div className='u_s'>
          <div className='useful_links'>
          <h6>Links</h6>
            <ul>
            <li>
        <Link to='/about' style={{color:'white'}}>About</Link>
        </li>
        <li>
        <Link to='/services' style={{color:'white'}}>Services</Link>
        </li>
        <li>
        <Link to='/workgallery' style={{color:'white'}}>Our Portfolio</Link>
        </li>
        <li>
        <Link to='/reveiws_feedback' style={{color:'white'}}>Reveiws</Link>
        </li>
        <li>
        <Link to='/appointus' style={{color:'white'}}>Hire Us</Link>
        </li>
            </ul>
          </div>
          <div className='socials'>
            <h6>Socials</h6>
            <ul>
            <li><Link style={{color:'white'}} target='_blank'  to='https://www.facebook.com/momentscaptureindia?mibextid=ZbWKwL'><FaIcons.FaFacebook/></Link></li>
                <li><Link style={{color:'white'}} target='_blank' to='https://www.instagram.com/momentscapture.in/'><FaIcons.FaInstagram/></Link></li>
                <li><Link style={{color:'white'}} target='_blank' to='https://www.linkedin.com/in/moments-capture-57b188303'><FaIcons.FaLinkedinIn/></Link></li>
                <li><Link style={{color:'white'}} target='_blank' to='https://pin.it/5tC3fvP'><FaIcons.FaPinterest/></Link></li>
                <li><Link style={{color:'white'}} target='_blank' to='https://x.com/M0mentsCapture?t=_II4evElwuqD2xhcw3RDDQ&s=09'><FaIcons.FaXTwitter/></Link></li>
            </ul>
          </div>
          </div>
      </div>
      <div className='otherinfo'>
            <p>Developed & designed by-</p><Link to='https://www.instagram.com/_shen_77/#' target='_target' style={{color:'white'}}><p><FaIcons.FaInstagram/>Muhammad Shine</p></Link>
          </div>
    </div>
  )
}

export default Footer

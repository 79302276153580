import React, { useEffect } from 'react';
import { useAuth } from '../utils/AuthContext.js';
import { Client } from 'appwrite';
import emailjs from 'emailjs-com'; // Import EmailJS
import './Reveiws & Feedback.css'
const ReveiwsFeedback = () => {
  const { user } = useAuth();
  useEffect(() => {
    if (user && user.email) {
      // Fetch user data or profile image from Appwrite
      fetchUserData(user);
    }
  }, [user]);

  const fetchUserData = async (user) => {
    try {
      const client = new Client(); // Create a new instance of the Appwrite client
      // Fetch user data including profile image from Appwrite
      const response = await client.users.get(user.$id);
      // Assuming the profile image is stored in the user's metadata
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const reveiwclick = (event) => {
    event.preventDefault();
    const reviewContent = document.querySelector('.reveiw-input').value;
    if (reviewContent != 0){
    alert('Thank u for your review');

    // Get the input value
    

    // Send email using EmailJS
    emailjs.send("service_wrxe8js","template_tffphvx", {
      to_email: 'in.momentscapture@gmail.com', // Change this to your email address
      from_name: user.email,
      message: reviewContent
    }, 'mX_NdFATp_MosUAk3')
      .then((response) => {
        console.log('Email sent successfully:', response);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });

    // Create a new div element for the review
    const div = document.createElement('div');
    div.className = 'reveiw-div';

    // Create a new h6 element for the user's name
    const h6 = document.createElement('h6');
    h6.className = 'reveiw-h6';
    h6.textContent = user.name;

    // Create a new p element for the review content
    const p = document.createElement('p');
    p.className = 'reveiw-content';
    p.textContent = reviewContent;

    // Append the h6 and p elements to the div
    div.appendChild(h6);
    div.appendChild(p);

    // Append the div to the reveiw-main div
    document.querySelector('.reveiw-main').appendChild(div);
  }
  else{
    alert('add a reveiw')
  }
  };

  return (
    <div className='reveiw-box'>
    <div className='reveiw-main'>
      <form>
        <h6>Leave a Review</h6>
        <input type='text' required value={user.name} className='reveiw-name' />
        <input type='text' maxLength={400} required className='reveiw-input' placeholder='add your reveiws here' />
        <input type='submit' onClick={reveiwclick} />
      </form>
    </div>
    </div>
  );
};

export default ReveiwsFeedback;

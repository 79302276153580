import React, { useState } from 'react';
import './Navbar.css';
import Sidebar from './Sidebar.jsx';
import * as FaIcons from "react-icons/fa6";
import {useNavigate} from 'react-router-dom'
const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate()
  const handleSearch = () => {
    // Logic to handle search
    if (searchTerm.trim() !== '') {
      // If search term is not empty, navigate to '/services'
      window.location.href = '/services';
    }
  };
  const usersearch = () =>{
    navigate('/profile')
  }

  return (
    <div className='main_div'>
      <Sidebar className='sidebar'/>
      <div className='navbar'>
        <button className='user-login' onClick={usersearch}>
        <FaIcons.FaUser className='user-login'/>
        </button>
        
        <h3 className='main-heading'>MOMENTS CAPTURE</h3>
        <input 
          type="text" 
          placeholder='    search here' 
          className='search-input' 
          value={searchTerm} 
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className='search-btn' onClick={handleSearch}>
          <FaIcons.FaMagnifyingGlass/>
        </button>
      </div>
    </div>
  );
};

export default Navbar;

import React,{useEffect ,useRef} from 'react'
import { Link ,useNavigate} from 'react-router-dom'
import { useAuth } from '../utils/AuthContext'
import './Login.css'
import Sociallogin from './Sociallogin'
const Login = () => {
    const navigate = useNavigate()
    const {user,loginUser} = useAuth()

    useEffect(()=>{
          if(user){
            navigate('/')
          }
    },[])

    const loginForm = useRef(null)

    const handleSubmit = (e) => {
      e.preventDefault()
      const email = loginForm.current.email.value
      const password = loginForm.current.password.value
      
      const userInfo = {email, password}
  
      loginUser(userInfo)
    }
  

  return (
    <>
    <div className='login-body'>
   
    <div className="container">
        <div className="login-register-container">
          <form onSubmit={handleSubmit} ref={loginForm} >

            <div className="form-field-wrapper">
                <label>Email:</label>
                <input 
                  required
                  type="email" 
                  name="email"
                  placeholder="Enter email..."
                  />
            </div>

            <div className="form-field-wrapper">
                <label>Password:</label>
                <input 
                  type="password" 
                  name="password"
                  placeholder="Enter password..."
                  />
            </div>


            <div className="form-field-wrapper">
    
                <input 
                  type="submit" 
                  value="Login"
                  className="btn"
                  />

            </div>

          </form>

          <p>Don't have an account? <Link to="/register">Register</Link></p>
          <Sociallogin/>
        </div>
        
    </div>
    </div>

    </>
  )
}

export default Login
import React from 'react'
import './About.css'
import imageabout from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/aboutimage.jpg'
import bappi from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/bappi.png'
import najir from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/najir.png'
import shine from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/shine.png'
import team from 'C:/Users/sufia/OneDrive/Documents/App/Front End/momentscapture/src/Components/Assests/team.png'
import { Link } from 'react-router-dom'
const About = () => {
  return (
    <>
      <div className='about'>
        <img src={imageabout} className='aboutimage' />
        <div className='f_container'>
          <h5 className='about_h5'>About</h5>
          <p className='about_subhead'>
            "Discover MomentsCapture: where stories come to life through captivating visuals. Join us in preserving cherished memories with our expert eye for detail and passion for storytelling."</p>
          <div className='mabout'>
            <div className='first_mabout'>
              Welcome to MomentsCapture, where every frame tells a story and every video unfolds a journey. We are passionate visual storytellers dedicated to freezing moments in time, encapsulating the essence of life's most cherished memories. With a keen eye for detail and a deep appreciation for the beauty that surrounds us, we strive to craft captivating photographs and mesmerizing videos that resonate with emotion and authenticity.
            </div>
            <div className='second_mabout'>
              Whether it's documenting a milestone event, preserving the magic of everyday life, or capturing the breathtaking wonders of the world, MomentsCapture is committed to transforming fleeting moments into timeless treasures. Join us on this visual odyssey as we celebrate life, love, and the art of storytelling through the lens.
            </div>
          </div>
          <div className='team_content'>
            <h5 className='team_h5'>Team</h5>
            <div className='members'>
              <div className='member_1div'>

                <div class="card_member">
                  <img src={bappi} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">Bappi Hussain</h5>
                    <p class="card-text card-text-bappi">Designation:<br/> Chairman & Co-Founder</p>
                  </div>
                </div>

                <div class="card_member" >
                  <img src={najir} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">Najir Uddin</h5>
                    <p class="card-text card-text-najir">Designation :<br/> Ceo & Co-Founder</p>
                  </div>
                </div>
              </div>

              <div className='member_2div'>
                <div class="card_member">
                  <img src={shine} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">Muhammad Shine</h5>
                    <p class="card-text">Designation :<br/> President & Devoleoper.</p>
                  </div>
                </div>

                <div class="card_member">
                  <img src={team} class="card-img-top" alt="..." />
                  <div class="card-body">
                    <h5 class="card-title">Become a member</h5>
                    <p class="card-text">Join Us , Grow with Us.<br/><Link to='https://wa.me/7291894384' target='_blank'>Reach our team</Link></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
